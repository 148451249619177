import styled from 'styled-components';

export const Footer = styled.footer`
  min-height: 180px;
  background: #003862;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Icons = styled.footer`

  img {
    width: 22px;
    height: 22px;
    background-color: #FFF;
    margin: 15px 8px ;
  }
`

export const SubDescription = styled.span`
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 5px;
  font-size: 16px;
  color: #FFF;
`