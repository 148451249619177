import React, { useState } from 'react'
import * as S from './styles';
import { Parallax } from "react-parallax";
import BackgroundPrincipal from '../assets/backgroundPrincipal.jpg';

import Sobre from '../components/sobre/Sobre';
import Servicos from '../components/servicos/Servicos';
import Footer from '../components/footer/Footer';

import Arrow from '../assets/arrow.svg';

function Home() {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop)
  return (
    <S.Container>
      <Parallax bgImage={BackgroundPrincipal} strength={500}
        bgImageStyle={{ height: '95vh', width: '100vw' }}	 >
        <S.TitleImage>
          <h1>BEM-VINDO A ETIO SOLUÇÕES</h1>
          <span>A Empresa de TI que você confia.</span>
        </S.TitleImage>
      </Parallax>

      <Sobre />
      <Servicos />

      <Footer />
      <S.Button onClick={scrollTop} style={{ height: 40, display: showScroll ? 'flex' : 'none' }} >
        <img src={Arrow} alt="Go to Top" />
      </S.Button>
    </S.Container>
  )
}

export default Home
