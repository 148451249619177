import React, { useState } from 'react'
import * as S from './styles';
import { Element } from 'react-scroll'

function Servicos() {
  const [images] = useState([
    {
      title: "ARMAZENE SEUS DADOS",
      description: "Oferecemos soluções para armazenar os dados da sua empresa, de forma segura, eficaz e confiável. Com alta disponibilidade dos dados, totalmente em nuvem, altamente escalável.",
      img: "https://static.wixstatic.com/media/e77fd1ddd12e4652b471fe86db43b889.jpg/v1/fill/w_535,h_300,al_c,q_80,usm_0.66_1.00_0.01/e77fd1ddd12e4652b471fe86db43b889.webp",
    },
    {
      title: "SOLUÇÕES DIGITAIS",
      description: "Prezando sempre pela inovação, a Etio Soluções conta com profissionais capacitados para transformar seu negócio, e com idéias prontas para revolucionar, automatizar e agilizar seus processos, aplicando sempre as melhores práticas em seus projetos e soluções.",
      img: "https://static.wixstatic.com/media/0c75179372a945708430e7f8fd3fdad7.jpg/v1/fill/w_535,h_300,al_c,q_80,usm_0.66_1.00_0.01/0c75179372a945708430e7f8fd3fdad7.webp",
    },
    {
      title: "CONSULTORIA E OUTSOURCING",
      description: "Nossos profissionais sempre atentos as tecnologias e com soluções capazes de transformar seu negócio. Oferecemos uma parceria com nossos clientes, onde cuidamos da parte tecnológica e deixamos o cliente focado no que realmente interessa, o seu negócio.",
      img: "https://static.wixstatic.com/media/a1a5c46effc243fba60aac7493349d62.jpg/v1/fill/w_535,h_300,al_c,q_80,usm_0.66_1.00_0.01/a1a5c46effc243fba60aac7493349d62.webp",
    },
  ])

  return (
    <S.Container>
      <Element name="servicos"></Element>
      <S.Title>NOSSOS SERVIÇOS</S.Title>
      <S.Grid>
        {images.map((item, index) => (
          <S.Card key={index}>
            <S.Image src={item.img} alt={item.title} />
            <S.Content>
              <S.TitleCard>{item.title}</S.TitleCard>
              <S.Description>
                {item.description}
              </S.Description>
            </S.Content>
          </S.Card>
        ))}
      </S.Grid>
    </S.Container>
  )
}

export default Servicos;
