import styled from 'styled-components';

export const Container = styled.div`
`

export const TitleImage = styled.div`
  font-family: 'Zilla Slab';
  color: #FFF;
  width: 100vw;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 64px;
    margin: 0;
    text-align: center;
  }

  span {
    font-size: 38px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 54px;
      margin: 0;
    }

    span {
      font-size: 32px;
    }
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 44px;
      margin: 0;
    }

    span {
      font-size: 28px;
    }
  }

  @media (max-width: 650px) {
    h1 {
      font-size: 28px;
      margin: 0;
    }

    span {
      font-size: 18px;
    }
  }
`

export const Button = styled.div`
    position: fixed; 
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    cursor: pointer;
    border-radius: 10px;
    background: #CCC;
    width: 40px;
    height: 40px;
    right: 20px;
    opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  img {
    width: 20px;
    height: 20px;
    transform: rotate(270deg);
    opacity: 1;
  }
`