import React from 'react'
import * as S from './styles';
import { Element } from 'react-scroll'

import IFacebook from '../../assets/facebook.svg';
import ILinkedin from '../../assets/linkedin.svg';

function Footer() {
  return (
    <S.Footer>
      <Element name="contato" />

      <S.Icons>
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/etiosolucoes/">
          <img src={IFacebook} alt="Icone facebook" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://br.linkedin.com/company/etiosolucoes?trk=ppro_cprof">
          <img src={ILinkedin} alt="Icone linkedin" />
        </a>
      </S.Icons>

      <S.SubDescription>comercial@etiosolucoes.com.br</S.SubDescription>
      <S.SubDescription>(11) 96174-9255</S.SubDescription>
      <br />

      <S.SubDescription>©2018 by Etio Soluções.</S.SubDescription>
    </S.Footer>
  )
}

export default Footer
