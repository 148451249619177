import React, { useEffect } from 'react'
import * as S from './styles';
import { Link, Events } from 'react-scroll'
import Logo from '../../assets/etio.png';

type Props = {
  open: boolean;
}

function RightNav(props: Props) {
  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
    });

    Events.scrollEvent.register('end', function () {
    });


    return () => {
      Events.scrollEvent.remove('begin')
      Events.scrollEvent.remove('end')
    }
  }, []);

  return (
    <S.Ul open={props.open}>
      <S.LogoUl src={Logo} alt={'Etio Soluções'} />

      <Link activeClass="active" to="sobre" spy={true} smooth={true} duration={500} >
        <li>Sobre</li>
      </Link>
      <Link activeClass="active" to="servicos" spy={true} smooth={true} duration={500} >
        <li>Serviços</li>
      </Link>
      <Link activeClass="active" to="contato" spy={true} smooth={true} duration={500} >
        <li>Contato</li>
      </Link>
    </S.Ul>
  )
}

export default RightNav
