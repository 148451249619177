import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px 0px 50px;

  @media (max-width: 1480px) {
    min-height: 70vh;
  }

  @media (max-width: 1000px) {
    padding: 0px 30px 0px 30px;
  }
`

export const Card = styled.div`
  background: #FFF;
  width: 100vw;
  margin: 10% 0 10% 0;
  box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 24%);
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Image = styled.img`
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
    height: 450px;
  }

  @media (max-width: 600px) {
    height: 320px;
  }

  @media (max-width: 450px) {
    height: 250px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8%;
`

export const Description = styled.span`
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 450px) {
    font-size: 12px;
  }
`

export const Title = styled.h1`
  font-size: 46px;
  color: #003862;

  @media (max-width: 1200px) {
    font-size: 38px;
  }

  @media (max-width: 1000px) {
    margin-top: 0;
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }

  @media (max-width: 450px) {
    font-size: 20px;
  }
`

export const Button = styled.button`
  width: 150px;
  height: 40px;
  outline: none;
  background: #003862;
  transition: all 0.5s;
  color: #FFF;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 50px;

  &:hover {
    background: #496e88;
  }

  @media (max-width: 1000px) {
    font-size: 12px;
    width: 120px;
    height: 35px;
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    margin: 30px 0 0 auto;
  }
`
