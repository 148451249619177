import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from './components/menu/Navbar';
import GlobalsStyles from './globalStyles';
import Home from './pages/Home';

ReactDOM.render(
  <React.StrictMode>
    <GlobalsStyles />
    <Navbar />
    <Home />
  </React.StrictMode>,
  document.getElementById('root')
);

