import React from 'react'
import * as S from './styles';
import { Element } from 'react-scroll'
import { Link } from 'react-scroll'

var IMAGE_LOGO = 'https://static.wixstatic.com/media/94529b508b40459fb23e9c35b7e7cc0c.jpg/v1/fill/w_843,h_860,al_t,q_85,usm_0.66_1.00_0.01/94529b508b40459fb23e9c35b7e7cc0c.webp'

function Sobre() {
  return (
    <S.Container>
      <S.Card>
        <Element name="sobre"></Element>
        <S.Image src={IMAGE_LOGO} alt="Sobre Nós" />
        <S.Content>
          <S.Title>
            QUEM SOMOS NÓS
          </S.Title>
          <S.Description>
            Surgiu da união de profissionais com experiência no segmento de TI,
            com participações em projetos de alta complexidade e disponibilidade e
            que passaram a criar soluções de informática focadas para pequenas e
            médias empresas, com qualidade e baixo custo.
            Nossa missão é tornar sua empresa mais produtiva com os recursos de tecnologia,
            proporcionando maior segurança das informações, serviços especializados e
            consultoria profissional. Sempre cumprindo com excelência todos os compromissos
            assumidos com o cliente.
          </S.Description>
          <Link activeClass="active" to="contato" spy={true} smooth={true} duration={500} >
            <S.Button>
              Entre em contato
            </S.Button>
          </Link>
        </S.Content>
      </S.Card>
    </S.Container>
  )
}

export default Sobre
