import styled from 'styled-components';

export const Container = styled.div`
  min-height: 90vh;
  height: 100%;
  margin: 0 0 8% 0;
  
  @media (max-width: 1480px) {
    min-height: 70vh;
  }
`

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px 0px 50px;
  flex-wrap: wrap;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  @media (max-width: 920px) {
    padding: 20px;
  }
`

export const Card = styled.div`
  background: #FFF;
  margin: 30px;
  max-width: 350px;
  box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 24%);
  display: flex;
  height: 600px;
  flex-direction: column;

  @media (max-width: 920px) {
    min-height: 400px;
    height: 100%;
    width: 280px;
  }

  @media (max-width: 640px) {
    min-width: 300px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 250px;

  @media (max-width: 920px) {
    min-height: 150px;
    max-height: 150px;
  }
`

export const Description = styled.span`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: normal;

  @media (max-width: 920px) {
    font-size: 13px;
  }
`

export const Title = styled.h1`
  color: #003862;
  width: 100%;
  text-align: center;
  margin: 0 0 100px 0;

  @media (max-width: 920px) {
    margin: 0 0 40px 0;
  }
`

export const TitleCard = styled.h1`
  font-size: 28px;
  height: 50px;
  margin: 0 0 50px 0;
  color: #003862;
  text-align: center;


  @media (max-width: 920px) {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 10px 0;
  }
`
